import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const selectArtistProfileFeature = (state: AppState) => state.artistProfile;

export const selectArtistProfileForCurrentUser = createSelector(
  selectArtistProfileFeature,
  (ap) => {
    return {
      profile: ap.artistProfileForCurrentUser,
      isLoading: ap.isLoadingArtistProfileForCurrentUser,
    };
  },
);

export const selectArtistProfile = createSelector(
  selectArtistProfileFeature,
  (ap) => {
    return {
      profile: ap.artistProfile,
      isLoading: ap.isLoadingArtistProfile,
    };
  },
);

export const selectListedMusicPieces = createSelector(
  selectArtistProfileFeature,
  (ap) => {
    return {
      listedMusicPieces: ap.listedMusicPieces || [],
      isLoadingListedMusicPieces: ap.isLoadingListedMusicPieces,
      previews: ap.previews,
    };
  },
);

export const selectListedCollections = createSelector(
  selectArtistProfileFeature,
  (ap) => ({
    isLoadingListedCollections: ap.isLoadingListedCollections,
    listedCollections: ap.listedCollections || [],
  }),
);

export const selectFocusMusicPiece = createSelector(
  selectArtistProfileFeature,
  (ap) => {
    return {
      focusMusicPiece: ap.focusMusicPiece,
    };
  },
);

export const selectPossibleTeasers = createSelector(
  selectArtistProfileFeature,
  (ap) => ({
    possibleTeasers: ap.possibleTeasers,
    isLoadingPossibleTeasers: ap.isLoadingPossibleTeasers,
  }),
);

export const selectTeaser = createSelector(
  selectArtistProfileFeature,
  (ap) => ({
    teaser: ap.teaser,
    isLoadingTeaser: ap.isLoadingTeaser,
  }),
);
