import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const faqFeature = (state: AppState) => state.faq;

export const items = createSelector(faqFeature, (e) => ({
  items: e.items,
  isLoadingItems: e.isLoadingItems,
}));

export const searchResults = createSelector(faqFeature, (e) => ({
  searchResults: e.searchResults,
  isLoadingSearchResults: e.isLoadingSearchResults,
  query: e.query,
}));
