export enum CollectionPurchaseStatus {
  PURCHASED,
  PENDING_PAYMENT,
  REQUIRES_PAYMENT,
}
export interface CollectionPurchase {
  name: string;
  price: number;
  purchasedBy: string;
  collection: string;
  creation: Date;
  clientSecret: string;
  purchaseStatus: CollectionPurchaseStatus;
  nameOfCollection: string;
  platformFee: number;
  totalSalesTax: number;
  paymentIntentStatus: string;
  paymentDate?: Date;
}
