import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const exploreFeature = (state: AppState) => state.explore;

export const searchResults = createSelector(exploreFeature, (e) => ({
  partnerSearchResults: e.partnerSearchResults,
  listedMusicPiecesSearchResults: e.listedMusicPiecesSearchResults,
  previewsForListedMusicPiecesSearchResults:
    e.previewsForListedMusicPiecesSearchResults,
  listedCollectionsSearchResults: e.listedCollectionsSearchResults,

  isLoadingSearchResults:
    e.isLoadingListedCollectionSearchResults ||
    e.isLoadingListedMusicPiecesSearchResults ||
    e.isLoadingPartnerSearchResults ||
    e.isLoadingPreviewsForListedMusicPiecesSearchResults,
  query: e.query,
}));

export const items = createSelector(exploreFeature, (e) => ({
  items: e.items,
  isLoadingItems: e.isLoadingItems,
}));
