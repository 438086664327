import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { MusicPieceSelectors } from ".";
import { CollectionItemToDisplayHelper } from "src/app/models/collection/collection-item-to-display";

const practiceRoutineFeature = (state: AppState) => state.practiceRoutine;

export const practiceRoutine = createSelector(practiceRoutineFeature, (e) => ({
  isLoading: e.isLoading,
  practiceRoutine: e.practiceRoutine,
  collectionType: e.collectionType,
}));

export const practiceConfirmations = createSelector(
  practiceRoutineFeature,
  (e) => ({
    isLoadingPracticeConfirmations: e.isLoadingPracticeConfirmations,
    practiceConfirmations: e.practiceConfirmations,
  }),
);

export const linkedCollectionItemsToDisplay = createSelector(
  practiceRoutineFeature,
  MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
  MusicPieceSelectors.selectIsLoadingMusicPieces,
  (practiceRoutineState, musicPiecesState, isLoadingMusicPiecesState) => {
    if (
      practiceRoutineState.linkedCollectionItems.length > 0 &&
      musicPiecesState.linkedMusicPieces.length > 0
    ) {
      return {
        items: CollectionItemToDisplayHelper.fromLinkedCollectionItems(
          practiceRoutineState.linkedCollectionItems,
          musicPiecesState.linkedMusicPieces,
        ),
        isLoading:
          practiceRoutineState.isLoadingCollectionItems ||
          isLoadingMusicPiecesState,
      };
    }
    return {
      isLoading: true,
      items: [],
    };
  },
);

export const collectionItemsToDisplay = createSelector(
  practiceRoutineFeature,
  MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
  MusicPieceSelectors.selectIsLoadingMusicPieces,
  (practiceRoutineState, musicPiecesState, isLoadingMusicPiecesState) => {
    if (
      practiceRoutineState.collectionItems.length > 0 &&
      musicPiecesState.musicPieces.length > 0
    ) {
      return {
        items: CollectionItemToDisplayHelper.from(
          practiceRoutineState.collectionItems,
          musicPiecesState.musicPieces,
          musicPiecesState.linkedMusicPieces || [],
        ),
        isLoading:
          practiceRoutineState.isLoadingCollectionItems ||
          isLoadingMusicPiecesState,
      };
    }

    return {
      isLoading: true,
      items: [],
    };
  },
);

export const practiceItems = createSelector(
  linkedCollectionItemsToDisplay,
  collectionItemsToDisplay,
  (lci, ci) => ({
    collectionItems: ci.items,
    isLoadingCollectionItems: ci.isLoading,
    linkedCollectionsItems: lci.items,
    isLoadingLinkedCollectionItems: lci.isLoading,
  }),
);

export const playerPracticeRoutine = createSelector(
  practiceRoutineFeature,
  (e) => ({
    isLoadingPlayerCollectionItem: e.isLoadingPlayerCollectionItem,
    playerCollectionItem: e.playerCollectionItem,
    playerCollectionType: e.playerCollectionType,
  }),
);
