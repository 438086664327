import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const notificationFeature = (state: AppState) => state.notification;

export const notifications = createSelector(notificationFeature, (state) => ({
  notifications: [...state.notifications].sort(
    (a, b) => b.creation.valueOf() - a.creation.valueOf(),
  ),
  isLoadingNotifications: state.isLoadingNotifications,
}));

export const unreadNotifications = createSelector(notifications, (state) => {
  const unreadNotifications = state.notifications.filter((n) => !n.read);
  return {
    hasUnreadNotifications: unreadNotifications.length > 0,
    unreadNotifications: unreadNotifications,
    isLoadingNotifications: state.isLoadingNotifications,
  };
});

export const markAsRead = createSelector(notificationFeature, (state) => ({
  isMarkingAllAsRead: state.isMarkingAllAsRead,
  isMarkingAsRead: state.isMarkingAsRead,
}));
