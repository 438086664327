import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const practiceRoutinesFeature = (state: AppState) => state.practiceRoutines;

export const practiceRoutines = createSelector(
  practiceRoutinesFeature,
  (e) => ({
    isLoading: e.isLoading,
    practiceRoutines: e.practiceRoutines,
  }),
);
