import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const playlistFeature = (state: AppState) => state.playlist;

export const playlist = createSelector(playlistFeature, (e) => ({
  playlistItems: e.playlistItems,
  isLoadingPlaylist: e.isLoadingPlaylist,
  playlist: e.playlist,
  playlistType: e.playlistType,
  currentPlaylistItemName: e.currentPlaylistItemName,
}));
