import { createSelector } from "@ngrx/store";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import {
  CollectionPurchase,
  CollectionPurchaseStatus,
} from "src/app/models/collection/collection-purchase";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import {
  MusicPiecePurchase,
  MusicPiecePurchaseStatus,
} from "src/app/models/music-piece/music-piece-purchase";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import {
  VideoFeedbackPurchase,
  VideoFeedbackPurchaseStatus,
} from "src/app/models/video-feedback/video-feedback-purchase";
import { AppState } from "../reducers";

export const purchaseFeature = (state: AppState) => state.purchase;

export const selectPurchasedItems = createSelector(purchaseFeature, (pu) => {
  return {
    items: [
      ...pu.purchasedMusicPieces.map(
        (mp) =>
          ({
            item: mp,
            type: "music-piece",
          }) as PurchasedItem,
      ),
      ...pu.purchasedCollections.map(
        (pc) =>
          ({
            item: pc,
            type: "collection",
          }) as PurchasedItem,
      ),
      ...pu.purchasedVideoFeedbacks.map(
        (pv) =>
          ({
            item: pv,
            type: "video-feedback",
          }) as PurchasedItem,
      ),
    ].sort((a, b) => b.item.creation.valueOf() - a.item.creation.valueOf()),
    isLoading:
      pu.isLoadingCollectionPurchases ||
      pu.isLoadingPurchasedMusicPieces ||
      pu.isLoadingVideoFeedbackPurchases,
  };
});

export const selectPurchaseSettings = createSelector(purchaseFeature, (p) => {
  return {
    purchaseSettings: p.purchaseSettings,
  };
});

export const selectCurrentPurchase = createSelector(purchaseFeature, (p) => {
  return {
    currentPurchase: p.currentPurchase,
  };
});

export enum PurchaseStatus {
  PURCHASED,
  NOT_PURCHASED,
  REQUIRES_PAYMENT,
  PENDING_PAYMENT,
  SELF_ITEM,
}

export interface PurchaseDetails {
  status: PurchaseStatus;
  listing: MusicPieceListing;
  musicPiecePurchase?: MusicPiecePurchase;
  linkedMusicPiece?: LinkedMusicPiece;
}

export interface CollectionPurchaseDetails {
  status: PurchaseStatus;
  listing: ShowcaseCollectionListing;
  collectionPurchase?: CollectionPurchase;
  linkedCollection?: StoredLinkedCollection;
}

export interface VideoFeedbackPurchaseDetails {
  status: PurchaseStatus;
  artistProfile: StoredArtistProfile;
  videoFeedbackPurchase?: VideoFeedbackPurchase; // if there is an existing purchase
}

const musicPieceFeature = (state: AppState) => state.musicPiece;
const collectionFeature = (state: AppState) => state.collection;

// see: https://stripe.com/docs/payments/paymentintents/lifecycle
export const selectPurchaseDetailsForListing = (listing: MusicPieceListing) =>
  createSelector(purchaseFeature, musicPieceFeature, (pu, mp) => {
    const foundMusicPiece = mp.musicPieces.find(
      (m) => m.name == listing.musicPiece,
    );
    if (foundMusicPiece) {
      return {
        status: PurchaseStatus.SELF_ITEM,
        listing: listing,
      } as PurchaseDetails;
    }

    const found = pu.purchasedMusicPieces.find(
      (pmp) => pmp.musicPiece == listing.musicPiece,
    );

    if (found == undefined) {
      return {
        status: PurchaseStatus.NOT_PURCHASED,
        listing: listing,
      } as PurchaseDetails;
    } else if (found.purchaseStatus == MusicPiecePurchaseStatus.PURCHASED) {
      const foundLinkedMusicPiece = mp.linkedMusicPieces.find(
        (lmp) => lmp.linkedMusicPiece?.musicPiece == listing.musicPiece,
      );
      return {
        status: PurchaseStatus.PURCHASED,
        listing: listing,
        musicPiecePurchase: found,
        linkedMusicPiece: foundLinkedMusicPiece?.linkedMusicPiece,
      } as PurchaseDetails;
    } else if (
      found.purchaseStatus == MusicPiecePurchaseStatus.REQUIRES_PAYMENT
    ) {
      return {
        status: PurchaseStatus.REQUIRES_PAYMENT,
        listing: listing,
        musicPiecePurchase: found,
      } as PurchaseDetails;
    } else {
      return {
        status: PurchaseStatus.PENDING_PAYMENT,
        listing: listing,
      } as PurchaseDetails;
    }
  });

export const selectPurchaseDetailsForCollectionListing = (
  listing: ShowcaseCollectionListing,
) =>
  createSelector(purchaseFeature, collectionFeature, (pu, c) => {
    const foundCollection = c.collections.find(
      (c) => c.name == listing.collection,
    );
    if (foundCollection) {
      return {
        status: PurchaseStatus.SELF_ITEM,
        listing: listing,
      } as CollectionPurchaseDetails;
    }

    const found = pu.purchasedCollections.find(
      (pc) => pc.collection == listing.collection,
    );

    if (found == undefined) {
      return {
        status: PurchaseStatus.NOT_PURCHASED,
        listing: listing,
      } as CollectionPurchaseDetails;
    } else if (found.purchaseStatus == CollectionPurchaseStatus.PURCHASED) {
      const foundLinkedCollection = c.linkedCollections.find(
        (lc) => lc.collection == listing.collection,
      );
      return {
        status: PurchaseStatus.PURCHASED,
        listing: listing,
        collectionPurchase: found,
        linkedCollection: foundLinkedCollection,
      } as CollectionPurchaseDetails;
    } else if (
      found.purchaseStatus == CollectionPurchaseStatus.REQUIRES_PAYMENT
    ) {
      return {
        status: PurchaseStatus.REQUIRES_PAYMENT,
        listing: listing,
        collectionPurchase: found,
      } as CollectionPurchaseDetails;
    } else {
      return {
        status: PurchaseStatus.PENDING_PAYMENT,
        listing: listing,
      } as CollectionPurchaseDetails;
    }
  });

export const selectPurchaseDetailsForVideoFeedback = (
  artist: StoredArtistProfile,
) =>
  createSelector(purchaseFeature, (state) => {
    const unfinishedPurchase = state.purchasedVideoFeedbacks.find(
      (pvf) =>
        pvf.artist == artist.name &&
        (pvf.purchaseStatus == VideoFeedbackPurchaseStatus.REQUIRES_PAYMENT ||
          pvf.purchaseStatus == VideoFeedbackPurchaseStatus.PENDING_PAYMENT),
    );
    if (unfinishedPurchase) {
      var status: PurchaseStatus = PurchaseStatus.REQUIRES_PAYMENT;

      if (
        unfinishedPurchase.purchaseStatus ==
        VideoFeedbackPurchaseStatus.PENDING_PAYMENT
      ) {
        status = PurchaseStatus.PENDING_PAYMENT;
      }
      if (
        unfinishedPurchase.purchaseStatus ==
        VideoFeedbackPurchaseStatus.REQUIRES_PAYMENT
      ) {
        status = PurchaseStatus.REQUIRES_PAYMENT;
      }

      return {
        status: status,
        videoFeedbackPurchase: unfinishedPurchase,
        artistProfile: artist,
      } as VideoFeedbackPurchaseDetails;
    } else {
      return {
        status: PurchaseStatus.NOT_PURCHASED, // maybe we had a purchase of the video feedback from this artist in the past - however we don't care as we want to allow re-purchase
        videoFeedbackPurchase: undefined,
        artistProfile: artist,
      } as VideoFeedbackPurchaseDetails;
    }
  });

export const selectPurchaseDetails = createSelector(purchaseFeature, (pu) => {
  return {
    details: pu.detailPurchaseStatus,
    isLoading: pu.isLoadingDetailPurchaseStatus,
  };
});
