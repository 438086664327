"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MethodDataWrapper = exports.FrappeRequestHelper = void 0;
const http_1 = require("@angular/common/http");
class FrappeRequestHelper {
  // This class deals with frappe's internals
  filters = [];
  orFilters = [];
  fields = [];
  params = new http_1.HttpParams();
  pageLength;
  skip;
  orderBy;
  withFilters(filters) {
    filters.forEach(filter => this.withFilter(filter));
    return this;
  }
  withOrderBy(orderBy) {
    this.orderBy = orderBy;
    return this;
  }
  withFilter(filter) {
    if (filter.filterType() == "AND") {
      this.filters = [...this.filters, filter.filter()];
    } else {
      this.orFilters = [...this.orFilters, filter.filter()];
    }
    return this;
  }
  withFields(fields) {
    this.fields = fields;
    return this;
  }
  withPageLength(length) {
    if (length > 0) {
      this.pageLength = length;
    }
    return this;
  }
  withSkip(skip) {
    if (skip > 0) {
      this.skip = skip;
    }
    return this;
  }
  withParams(params) {
    params.forEach(param => this.withParam(param.key, param.value));
    return this;
  }
  withParam(key, value) {
    this.params = this.params.append(key, value);
    return this;
  }
  buildParams() {
    if (this.filters && this.filters.length > 0) {
      this.params = this.params.append("filters", JSON.stringify(this.filters));
    }
    if (this.orFilters && this.orFilters.length > 0) {
      this.params = this.params.append("or_filters", JSON.stringify(this.orFilters));
    }
    if (this.fields && this.fields.length > 0) {
      this.params = this.params.append("fields", JSON.stringify(this.fields));
    }
    if (this.pageLength) {
      this.params = this.params.append("limit_page_length", this.pageLength);
    } else {
      // frappe limits the entries to 20 per default.
      // see: https://frappeframework.com/docs/v13/user/en/api/rest#:~:text=the%20limit_start%20and-,limit_page_length,-params.
      this.params = this.params.append("limit_page_length", "None");
    }
    if (this.skip) {
      this.params = this.params.append("limit_start", this.skip);
    }
    if (this.orderBy) {
      this.params = this.params.append("order_by", this.orderBy);
    }
    return this.params;
  }
}
exports.FrappeRequestHelper = FrappeRequestHelper;
class MethodDataWrapper {
  params = new http_1.HttpParams();
  dto;
  withDto(dto) {
    this.dto = dto;
    return this;
  }
  withParam(key, value) {
    this.params = this.params.append(key, value);
    return this;
  }
  buildParams() {
    return this.params;
  }
  getDto() {
    return this.dto;
  }
}
exports.MethodDataWrapper = MethodDataWrapper;