import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const selectLicenseFeature = (state: AppState) => state.license;

export const selectUserLicense = createSelector(
  selectLicenseFeature,
  (state) => state.userLicense,
);

export const selectFallbackAndUpgrade = createSelector(
  selectLicenseFeature,
  (state) => {
    return {
      isLoadingFallbackAndUpgrade: state.isLoadingFallbackAndUpgrade,
      fallback: state.fallback,
      upgrade: state.upgrade,
    };
  },
);

export const selectCurrentCheckoutSession = createSelector(
  selectLicenseFeature,
  (state) => {
    return {
      checkoutSession: state.checkoutSession,
    };
  },
);
