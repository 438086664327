import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { MusicPieceSelectors } from ".";

const selectEditCollection = (state: AppState) => state.editCollection;

export const selectEditCollectionData = createSelector(
  selectEditCollection,
  (ec) => {
    return {
      isNew: ec.isNew,
      isLoadingCollection: ec.isLoadingCollection,
      name: ec.name,
      collection: ec.collection,
    };
  },
);

export const selectEditCollectionItems = createSelector(
  selectEditCollection,
  MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
  (ec, musicPiecesAndLinkedMusicPieces) => {
    return {
      collectionItems: [...ec.collectionItems].sort(
        (a, b) => a.order - b.order,
      ),
      isNew: ec.isNew,
      isLoadingCollectionItems: ec.isLoadingCollectionItems,
      collection: ec.collection,
      musicPieces: musicPiecesAndLinkedMusicPieces.musicPieces,
      linkedMusicPieces: musicPiecesAndLinkedMusicPieces.linkedMusicPieces,
      isSaving: ec.isSaving,
    };
  },
);

export const selectFilteredCollectionItems = createSelector(
  selectEditCollection,
  (ec) => {
    return {
      filteredCollectionItems: ec.filteredCollectionItems,
    };
  },
);

export const selectEditCollectionItemsToAdd = createSelector(
  selectEditCollection,
  (ec) => {
    return {
      collectionItemsToAdd: ec.collectionItemsToAdd,
      filteredCollectionItemsToAdd: ec.filteredCollectionItemsToAdd,
      isLoadingCollectionsItemsToAdd: ec.isLoadingCollectionsItemsToAdd,
      isSaving: ec.isSaving,
    };
  },
);

export const selectLinkedCollections = createSelector(
  selectEditCollection,
  (ec) => {
    return {
      isSharing: ec.isSharing,
      linkedCollections: ec.linkedCollections,
      isLoadingLinkedCollections: ec.isLoadingLinkedCollections,
    };
  },
);

export const selectCollectionListing = createSelector(
  selectEditCollection,
  (ec) => ({
    listing: ec.listing,
    isLoadingCollectionListing: ec.isLoadingCollectionListing,
    isSavingCollectionListing: ec.isSavingCollectionListing,
  }),
);
