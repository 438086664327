import {
  DocWrapper,
  FrappeDoc,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";

export enum VideoFeedbackPurchaseStatus {
  PURCHASED,
  PENDING_PAYMENT,
  REQUIRES_PAYMENT,
}
export interface VideoFeedbackPurchase extends FrappeDoc {
  price: number;
  purchasedBy: string;
  artist: string;
  clientSecret: string;
  purchaseStatus: VideoFeedbackPurchaseStatus;
  platformFee: number;
  totalSalesTax: number;
  paymentIntentStatus: string;
  paymentDate?: Date;
}

export abstract class VideoFeedbackPurchaseConversionHelper {
  // required for interop with services.
  // not ideal: we leak the frappe names from the services to the models...

  public static wrapper(): DocWrapper<VideoFeedbackPurchase> {
    return mappingBuilderWithDefaultMappings<VideoFeedbackPurchase>(
      "Video Feedback Purchase",
      [
        mappingHelper.floatMapper("price"),
        mappingHelper.stringMapper("purchased_by", "purchasedBy"),
        mappingHelper.stringMapper("artist"),
        mappingHelper.stringMapper("client_secret", "clientSecret"),
        {
          frappeName: "docstatus",
          name: "purchaseStatus",
          map: (docStatus, dto): VideoFeedbackPurchaseStatus => {
            // 0 draft
            // 1 submitted
            // 2 cancelled
            if (docStatus == 1) {
              return VideoFeedbackPurchaseStatus.PURCHASED;
            } else if (
              dto.payment_intent_status == "requires_payment_method" ||
              dto.payment_intent_status == "" || //  initially we treat it as REQUIRES_PAYMENT
              !dto.payment_intent_status // initially we treat it as REQUIRES_PAYMENT
            ) {
              // dto.payment_intent_status should be available as we include the payment_intent_status in our field mappings below
              return VideoFeedbackPurchaseStatus.REQUIRES_PAYMENT;
            } else {
              return VideoFeedbackPurchaseStatus.PENDING_PAYMENT;
            }
          },
          mapToFrappe: (_) => {
            throw "can't change purchase status in the client!";
          },
        },

        mappingHelper.floatMapper("platform_fee", "platformFee"),
        mappingHelper.floatMapper("total_sales_tax", "totalSalesTax"),
        mappingHelper.stringMapper(
          "payment_intent_status",
          "paymentIntentStatus",
        ),
        mappingHelper.optionalDateMapper("payment_date", "paymentDate"),
      ],
    );
  }
}
