import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { MusicPieceToDisplay } from "../music-piece/music-piece-to-display";
import { StoredCollectionItem } from "./stored-collection-item";
import { MusicPiece } from "../music-piece/music-piece";
import { StoredLinkedCollectionItem } from "./stored-linked-collection-item";

export interface CollectionItemToDisplay {
  musicPiece: MusicPieceToDisplay;
  collectionItem: StoredCollectionItem;
}

export interface LinkedCollectionItemToDisplay {
  collectionItem: StoredLinkedCollectionItem;
  musicPiece: MusicPieceToDisplay;
}

export abstract class CollectionItemToDisplayHelper {
  static fromLinkedCollectionItems(
    collectionItems: StoredLinkedCollectionItem[],
    linkedMusicPieces: LinkedMusicPieceWithMusicPiece[],
  ): LinkedCollectionItemToDisplay[] {
    const collectionItemsToDisplay = collectionItems
      .map((ci) => {
        const foundPiece = linkedMusicPieces.find(
          (lmp) => lmp.linkedMusicPiece?.name == ci.linkedMusicPiece,
        );
        if (foundPiece) {
          return {
            collectionItem: ci,
            musicPiece: {
              isLinkedMusicPiece: true,
              musicPiece: foundPiece.musicPiece,
              linkedMusicPiece: foundPiece.linkedMusicPiece,
            },
          } as LinkedCollectionItemToDisplay;
        }

        return undefined;
      })
      .filter((citd) => {
        const isUndefined = citd == undefined;
        // console.log(`isUndefined(${citd})=${isUndefined}`);
        return !isUndefined;
      })
      .map((citd) => citd!);
    return collectionItemsToDisplay;
  }

  static from(
    collectionItems: StoredCollectionItem[],
    musicPieces: MusicPiece[],
    linkedMusicPieces: LinkedMusicPieceWithMusicPiece[],
  ): CollectionItemToDisplay[] {
    const collectionItemsToDisplay = collectionItems
      .map((ci) => {
        if (ci.linkedMusicPiece) {
          const foundPiece = linkedMusicPieces.find(
            (lmp) => lmp.linkedMusicPiece?.name == ci.linkedMusicPiece,
          );
          if (foundPiece) {
            return {
              collectionItem: ci,
              musicPiece: {
                isLinkedMusicPiece: true,
                musicPiece: foundPiece.musicPiece,
                linkedMusicPiece: foundPiece.linkedMusicPiece,
              },
            } as CollectionItemToDisplay;
          }
        } else if (ci.musicPice) {
          const foundPiece = musicPieces.find((mp) => mp.name == ci.musicPice);
          if (foundPiece) {
            return {
              collectionItem: ci,
              musicPiece: {
                isLinkedMusicPiece: false,
                musicPiece: foundPiece,
              },
            } as CollectionItemToDisplay;
          }
        }
        return undefined;
      })
      .filter((citd) => {
        const isUndefined = citd == undefined;
        // console.log(`isUndefined(${citd})=${isUndefined}`);
        return !isUndefined;
      })
      .map((citd) => citd!);
    return collectionItemsToDisplay;
  }
}
