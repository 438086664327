import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const videoFeedbackFeature = (state: AppState) => state.videoFeedback;

export const videoFeedback = createSelector(videoFeedbackFeature, (e) => ({
  isLoadingVideoFeedback: e.isLoadingVideoFeedback,
  videoFeedback: e.videoFeedback,
}));

export const videoFeedbackItems = createSelector(videoFeedbackFeature, (e) => ({
  isLoadingVideoFeedbackItems: e.isLoadingVideoFeedbackItems,
  videoFeedbackItems: e.videoFeedbackItems,
}));

export const isCreatingVideoFeedback = createSelector(
  videoFeedbackFeature,
  (e) => ({
    isCreatingVideoFeedbackItem: e.isCreatingVideoFeedbackItem,
  }),
);

export const isUpdatingTheme = createSelector(videoFeedbackFeature, (e) => ({
  isUpdatingTheme: e.isUpdatingTheme,
}));
