import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const selectCollection = (state: AppState) => state.collection;

export const selectCollections = createSelector(selectCollection, (c) => {
  return {
    collections: c.collections,
    linkedCollections: c.linkedCollections,
    collectionForLinkedCollections: c.collectionsForLinkedCollections,
    isLoadingCollections: c.isLoadingCollections,
  };
});

export const selectFilteredCollections = createSelector(
  selectCollection,
  (c) => {
    return {
      filteredCollections: c.filteredCollections,
      filteredLinkedCollections: c.filteredLinkedCollections,
      filteredCollectionsForLinkedCollections:
        c.filteredCollectionsForLinkedCollections,
      isLoadingCollections: c.isLoadingCollections,
    };
  }
);

export const selectCollectionsWithCollectionItems = createSelector(
  selectCollection,
  (c) => {
    return {
      collections: c.collections,
      linkedCollections: c.linkedCollections,
      collectionItems: c.collectionItems,
      linkedCollectionItems: c.linkedCollectionItems,
      collectionsForLinkedCollections: c.collectionsForLinkedCollections,
    };
  }
);

export const selectLinkedCollectionItems = createSelector(
  selectCollection,
  (c) => {
    return {
      linkedCollectionItems: c.linkedCollectionItems,
      isLoadingLinkedCollectionItems: c.isLoadingLinkedCollectionItems,
    };
  }
);
