import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const registration = (state: AppState) => state.registration;

export const selectIsLoading = createSelector(
  registration,
  (state) => state.isLoading
);
export const selectWasSuccessful = createSelector(
  registration,
  (state) => state.wasSuccessful
);
export const selectStoredPotentialUser = createSelector(
  registration,
  (state) => state.potentialUser
);
export const selectWasVerifiedSuccessful = createSelector(
  registration,
  (state) => state.wasVerifiedSuccessful
);
