export enum MusicPiecePurchaseStatus {
  PURCHASED,
  PENDING_PAYMENT,
  REQUIRES_PAYMENT,
}

export interface MusicPiecePurchase {
  name: string;
  price: number;
  purchasedBy: string;
  musicPiece: string;
  creation: Date;
  clientSecret: string;
  purchaseStatus: MusicPiecePurchaseStatus;
  nameOfMusicPiece: string;
  platformFee: number;
  totalSalesTax: number;
  paymentIntentStatus: string;
  paymentDate?: Date;
}
