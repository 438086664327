"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrappeCrudService = void 0;
const rxjs_1 = require("rxjs");
const frappe_request_helper_1 = require("./frappe-request-helper");
const url_sanitizer_1 = require("../../common/url-sanitizer");
class FrappeCrudService {
  http;
  config;
  constructor(http, config) {
    this.http = http;
    this.config = config;
  }
  querySingle(name, doctype) {
    const url = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/resource").addUrlPart(doctype).addUrlPart(name).url();
    const result = this.http.get(url);
    return result.pipe((0, rxjs_1.map)(result => result.data));
  }
  queryAll(doctype, fields,
  // we want the fields to be not part of the options as the fields must "always" match the fields in the conversion and is not user definable
  options) {
    const url = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/resource").addUrlPart(doctype).url();
    var requestHelper = new frappe_request_helper_1.FrappeRequestHelper();
    if (options) {
      if (options.filters) {
        requestHelper = requestHelper.withFilters(options.filters);
      }
      if (options.orderBy) {
        requestHelper = requestHelper.withOrderBy(`${options.orderBy.field} ${options.orderBy.direction}`);
      }
      if (options.pageLength) {
        requestHelper = requestHelper.withPageLength(options.pageLength);
      }
      if (options.skip) {
        requestHelper.withSkip(options.skip);
      }
      if (options.extraParams) {
        requestHelper.withParams(options.extraParams);
      }
    }
    requestHelper.withFields(fields);
    const result = this.http.get(url, {
      params: requestHelper.buildParams()
    });
    return result.pipe((0, rxjs_1.map)(result => result.data));
  }
  create(dto, doctype) {
    const url = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/resource").addUrlPart(doctype).url();
    const result = this.http.post(url, dto);
    return result.pipe((0, rxjs_1.map)(result => result.data));
  }
  update(name, dto, doctype) {
    const url = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/resource").addUrlPart(doctype).addUrlPart(name).url();
    const result = this.http.put(url, dto);
    return result.pipe((0, rxjs_1.map)(result => result.data));
  }
  delete(name, doctype) {
    const url = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/resource").addUrlPart(doctype).addUrlPart(name).url();
    const result = this.http.delete(url);
    return result.pipe((0, rxjs_1.map)(_ => undefined));
  }
}
exports.FrappeCrudService = FrappeCrudService;