import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { selectUserLicense } from "./license.selector";

const selectArtistCertificationFeature = (state: AppState) =>
  state.artistCertification;

export const selectArtistCertificationState = createSelector(
  selectArtistCertificationFeature,
  (s) => ({
    artistCertification: s.artistCertification,
    isCreating: s.isCreating,
    isLoading: s.isLoading,
    isRequestingReview: s.isRequestingReview,
  }),
);

export const selectArtistCertificationWithLicense = createSelector(
  selectArtistCertificationFeature,
  selectUserLicense,
  (ac, ul) => ({
    artistCertification: ac.artistCertification,
    isLoading: ac.isLoading,
    license: ul.license,
  }),
);
