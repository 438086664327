import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const usedResourcesForUsers = (state: AppState) => state.usedResourcesForUser;
const license = (state: AppState) => state.license;
export const selectUsedResourcesForUser = createSelector(
  usedResourcesForUsers,
  (state) => state,
);

export const selectUsedResourcesForUserWithLicense = createSelector(
  usedResourcesForUsers,
  license,
  (urfu, l) => {
    return {
      resources: urfu,
      license: l,
    };
  },
);

export const hasFreePracticeRoutines = createSelector(
  usedResourcesForUsers,
  license,
  (urfu, l) => {
    return l.userLicense.license.practiceRoutines == "unlimited"
      ? true
      : urfu.totalPracticeRoutines < l.userLicense.license.practiceRoutines;
  },
);

export const hasPracticeDurationLeft = createSelector(
  selectUsedResourcesForUserWithLicense,
  (pair) => {
    const hasPracticeDurationLeft =
      pair.license.userLicense.license.practiceDurationThisMonthMs ==
        "unlimited" ||
      pair.license.userLicense.license.practiceDurationThisMonthMs >
        pair.resources.practiceDurationThisMonthMs;
    return {
      hasPracticeDurationLeft: hasPracticeDurationLeft,
    };
  },
);
