import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { MusicPieceSelectors } from ".";

const readonlyCollectionFeature = (state: AppState) => state.readonlyCollection;

export const selectReadonlyCollection = createSelector(
  readonlyCollectionFeature,
  (state) => {
    return {
      collection: state.collection,
      linkedCollection: state.linkedCollection,
      isLoadingCollection: state.isLoadingCollection,
    };
  }
);

export const selectReadonlyCollectionItems = createSelector(
  readonlyCollectionFeature,
  MusicPieceSelectors.selectMusicPiecesAndLinkedMusicPieces,
  (readonlyCollectionState, musicPieces) => {
    return {
      collectionItems: readonlyCollectionState.collectionItems,
      isLoadingCollectionItems:
        readonlyCollectionState.isLoadingCollectionItems,
      musicPieces: musicPieces.musicPieces,
      linkedMusicPieces: musicPieces.linkedMusicPieces,
      filteredCollectionItems: readonlyCollectionState.filteredCollectionItems,
    };
  }
);
