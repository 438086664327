import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const selectMusicPiece = (state: AppState) => state.musicPiece;

export const selectMusicPieces = createSelector(selectMusicPiece, (mp) => ({
  musicPieces: mp.musicPieces,
}));

export const selectMusicPiecesAndLinkedMusicPieces = createSelector(
  selectMusicPiece,
  (mp) => {
    return {
      musicPieces: mp.musicPieces,
      linkedMusicPieces: mp.linkedMusicPieces,
    };
  },
);

export const selectIsLoadingMusicPieces = createSelector(
  selectMusicPiece,
  (mp) => mp.isLoadingLinkedMusicPieces || mp.isLoadingMusicPieces,
);

export const selectPreviews = createSelector(
  selectMusicPiece,
  (mp) => mp.musicPiecePreviews,
);

export const selectLinkedMusicPiecePreviews = createSelector(
  selectMusicPiece,
  (mp) => mp.linkedMusicPiecePreviews,
);

export const selectFilteredMusicPieces = createSelector(
  selectMusicPiece,
  (mp) => mp.filteredMusicPieces,
);
