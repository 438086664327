"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrappeMethodService = void 0;
const frappe_request_helper_1 = require("./frappe-request-helper");
const rxjs_1 = require("rxjs");
const url_sanitizer_1 = require("../../common/url-sanitizer");
class FrappeMethodService {
  http;
  config;
  constructor(http, config) {
    this.http = http;
    this.config = config;
  }
  buildDataWrapper(options) {
    var wrapper = new frappe_request_helper_1.MethodDataWrapper();
    if (options.body) {
      wrapper = wrapper.withDto(options.body);
    }
    if (options.extraParams) {
      options.extraParams.forEach((value, key) => {
        wrapper = wrapper.withParam(key, value);
      });
    }
    return wrapper;
  }
  callWithResults(options) {
    return this.callInternal(options.method, this.buildDataWrapper(options), options.type);
  }
  callWithResult(options) {
    return this.callInternal(options.method, this.buildDataWrapper(options), options.type);
  }
  call(options) {
    return this.callInternal(options.method, this.buildDataWrapper(options), options.type).pipe((0, rxjs_1.map)(_ => {
      return;
    }));
  }
  tryToFillDataFromMessage(result) {
    if (result.message && result.data == undefined) {
      result.data = result.message;
    }
    return result;
  }
  callInternal(frappeMethod, methodDataWrapper, httpMethod = "POST") {
    const urlSanitizer = new url_sanitizer_1.UrlSanitizer().addUrlPart(this.config.baseUrl).addUrlPart("/api/method").addUrlPart(frappeMethod);
    if (httpMethod == "GET") {
      return this.http.get(urlSanitizer.url(), {
        params: methodDataWrapper.buildParams()
      }).pipe((0, rxjs_1.map)(res => this.tryToFillDataFromMessage(res).data));
    } else if (httpMethod == "POST") {
      return this.http.post(urlSanitizer.url(), methodDataWrapper.getDto() || {}, {
        params: methodDataWrapper.buildParams()
      }).pipe((0, rxjs_1.map)(res => this.tryToFillDataFromMessage(res).data));
    } else if (httpMethod == "PUT") {
      return this.http.put(urlSanitizer.url(), methodDataWrapper.getDto() || {}, {
        params: methodDataWrapper.buildParams()
      }).pipe((0, rxjs_1.map)(res => this.tryToFillDataFromMessage(res).data));
    } else if (httpMethod == "DELETE") {
      return this.http.delete(urlSanitizer.url(), {
        params: methodDataWrapper.buildParams()
      }).pipe((0, rxjs_1.map)(res => this.tryToFillDataFromMessage(res).data));
    }
    throw `invalid method ${httpMethod}`;
  }
}
exports.FrappeMethodService = FrappeMethodService;