import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

export const salesFeature = (state: AppState) => state.sales;

export const selectSales = createSelector(salesFeature, (s) => {
  return {
    isLoadingSales: s.isLoadingSales,
    sales: s.sales,
  };
});

export const selectCreditStatements = createSelector(salesFeature, (s) => ({
  isLoadingCreditStatements: s.isLoadingCreditStatements,
  creditStatements: s.creditStatements,
  creditPayouts: s.creditPayouts,
  isLoadingCreditPayouts: s.isLoadingCreditPayouts,
}));
