"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubscriptionHelper = void 0;
class SubscriptionHelper {
  subs = [];
  constructor() {}
  add(sub) {
    this.subs.push(sub);
  }
  addMany(...subs) {
    subs.forEach(s => this.add(s));
  }
  unsubscribeAll() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
exports.SubscriptionHelper = SubscriptionHelper;