"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnitService = void 0;
const luxon_1 = require("luxon");
class UnitService {
  constructor() {}
  timestampMsToString(timestampMs, configuration) {
    // for now we format our ms as mm:ss or ss if we have < 1m
    const timestampSec = timestampMs / 1000;
    if (configuration?.useMinutesFormat || false) {
      const min = Math.floor(timestampSec / 60);
      const sec = Math.floor(timestampSec - min * 60);
      const minString = min < 10 ? `0${min}` : `${min}`;
      const secString = sec < 10 ? `0${sec}` : `${sec}`;
      if (min > 0 || configuration.alwaysShowMinutes) {
        return `${minString}:${secString}`;
      } else {
        return secString;
      }
    } else {
      const sec = Math.round(timestampSec);
      return `${sec}`;
    }
  }
  parseOptionalApiDate(date) {
    if (date) {
      return this.parseApiDate(date);
    }
    return;
  }
  parseApiDate(date) {
    return luxon_1.DateTime.fromISO(date.replace(" ", "T")).toJSDate();
  }
  dateToApiDate(date) {
    return luxon_1.DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss");
  }
}
exports.UnitService = UnitService;