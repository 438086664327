import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const notificationSettingsFeature = (state: AppState) =>
  state.notificationSettings;

export const notificationServerSettings = createSelector(
  notificationSettingsFeature,
  (state) => ({
    isLoadingServerSettings: state.isLoadingServerSettings,
    serverSettings: state.serverSettings,
  }),
);

export const notificationUserSettings = createSelector(
  notificationSettingsFeature,
  (state) => ({
    isLoadingUserSettings: state.isLoadingUserSettings,
    userSettings: state.userSettings,
    isSavingUserSettings: state.isSavingUserSettings,
  }),
);

export const pushNotificationSubscription = createSelector(
  notificationSettingsFeature,
  (state) => ({
    activeSubscription: state.activeSubscription,
    vapidPublicKey: state.serverSettings?.vapidPublicKey,
    pushNotificationSubscription: state.pushNotificationSubscription,
    isCreatingPushNotificationSubscription:
      state.isCreatingPushNotificationSubscription,
    isRegisteringSubscriptionInBrowser:
      state.isRegisteringSubscriptionInBrowser,
  }),
);
