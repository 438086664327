import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const videoFeedbacksFeature = (state: AppState) => state.videoFeedbacks;

export const videoFeedbacks = createSelector(videoFeedbacksFeature, (e) => ({
  isLoading: e.isLoading,
  videoFeedbacks: e.videoFeedbacks,
}));

export const filteredFeedbacks = createSelector(videoFeedbacksFeature, (e) => ({
  isLoading: e.isLoading,
  filteredVideoFeedbacks: e.filteredVideoFeedbacks,
  allVideoFeedbacks: e.videoFeedbacks,
}));
